@font-face {
  font-family: "NeueRational";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/NeueRationalCondensedTest-BlackItalic.otf");
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-1l135z1 {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  display: flex;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  background-color: rgb(168, 43, 115);
  padding: 0px 10px;
  height: 20px;
  transform: skew(-10deg);
  width: 100px;
}
@import "./components/MatchDetails/MatchDetails.scss";
