.landing-page {
  position: absolute;
  pointer-events: none;
  left: 0%;
  top: 0%;
  right: 0;
  bottom: 0;
  z-index: 999999;
  button {
    pointer-events: all;
  }
}
.landing-page__brain-ui {
  // position: absolute;
  // top: 0px;
  // left: 0;
  // right: 0;
  // bottom: 0;
}
.landing-page__brain-ui__back {
  position: absolute;

  top: 10px;
  left: 10px;
  transform: translate(0, -100px);
  transition-duration: 0.5s;
}
.landing-page__brain-ui__back--active {
  transform: translate(0, 0px);
}
.landing-page__brain-ui__confirm {
  position: absolute;

  bottom: 0;
  right: 50%;
  transform: translate(50%, 100px);
  transition-duration: 0.5s;
}
.landing-page__brain-ui__confirm--active {
  transform: translate(50%, -20px);
}
.landing-page__brain-ui__prev {
  position: absolute;

  bottom: 0;
  left: 20px;
  transform: translate(0, 100px);
  transition-duration: 0.5s;
}
.landing-page__brain-ui__prev--active {
  transform: translate(0, -20px);
}
.landing-page__brain-ui__next {
  position: absolute;

  bottom: 0;
  right: 20px;
  transform: translate(0, 100px);
  transition-duration: 0.5s;
}
.landing-page__brain-ui__next--active {
  transform: translate(0, -20px);
}
