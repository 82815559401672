$speed: 500ms;

.page-enter {
  .landing-page {
    opacity: 0;

    z-index: 1;
  }
  .team-page-container {
    .team-vs-opponent {
      opacity: 0;
      transform: translate(0, 25px);
      z-index: 1;
    }
    .button {
      opacity: 0;
    }
  }
}
.page-enter.page-enter-active {
  .landing-page {
    opacity: 1;
    transition: opacity $speed ease-out;
  }
  .team-page-container {
    .team-vs-opponent {
      opacity: 1;
      transform: translate(0, 0);

      transition: opacity $speed ease-out, transform $speed ease;
    }
    .button {
      opacity: 1;
      transition: opacity $speed ease-out;
    }
  }
}
.page-exit {
  .landing-page {
    opacity: 1;
  }
  .team-page-container {
    .team-vs-opponent {
      opacity: 1;
      transform: translate(0, 0);
    }
    .button {
      opacity: 1;
    }
  }
}
.page-exit.page-exit-active {
  .landing-page {
    opacity: 0;

    transition: opacity $speed ease-out, transform $speed ease;
  }

  .team-page-container {
    .team-vs-opponent {
      opacity: 0;
      transform: translate(0, -30px);

      transition: opacity $speed ease-out, transform $speed ease;
    }
    .button {
      opacity: 0;
      transition: opacity $speed ease-out;
    }
  }
}
