$desktop: 1024px;
.match-details {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}
.match-details__grid {
  // min-width: 89%;
  font-family: "NeueRational";
  display: grid;
  grid-template-areas:
    ". logo ."
    "team vs opponent";
  grid-template-columns: 1fr 0.5fr 1fr;
  color: #2d6025;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  // var(--padding): 5px;
  --padding: 5px;
  width: 90%;
  @media only screen and (min-width: $desktop) {
    --padding: 15px 50px 15px 50px;
    font-size: 30px;
  }

  .grid-item--logo {
    grid-area: logo;
    padding: 8px;
    text-align: center;
    color: #2d6025;
    background: #dcedc1;
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    img {
      max-width: 100%;
      // width: 90px;
    }
  }
  .grid-item--team {
    grid-area: team;
    padding: var(--padding);
    border-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background: #dcedc1;
  }
  .grid-item--vs {
    grid-area: vs;
    padding: var(--padding);
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    background: #2d6025;
    color: #b2d780;
  }
  .grid-item--opponent {
    grid-area: opponent;
    padding: var(--padding);
    border-radius: 30px;
    background: #dcedc1;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
