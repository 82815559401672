.stats {
  position: relative;
}
.stats-list {
  list-style: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
}
.stats-list__item {
  color: white;
  font-family: "NeueRational";
  font-size: 95px;
  p {
    margin: 25px;
    padding: 0;
    text-align: center;
    line-height: 0.55;
    &:nth-child(2) {
      font-size: 25px;
    }
  }
}

.stats-list__item-label {
  text-align: center;
  // display: inline-block;
  width: 200px;
  // border: 1px solid red;
  // font-size: 20px;
}
.stats-list__item-animate-me {
  position: relative;
  animation: move-text-color 0.75s forwards;
  bottom: -1em;
  opacity: 0;
}

@keyframes move-text-color {
  0% {
    bottom: -0.2em;
    opacity: 1;
    // color: #3e5c76;
  }

  50% {
    bottom: 0.2em;
    // color: #ec058e;
  }

  100% {
    bottom: 0;
    opacity: 1;
    // color: #cb3126;
  }
}
